<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form :inline="true" label-position="right" :model="formInline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Program_Name')">
                <el-input
                  v-model="formInline.programName"
                  placeholder="请输入节目名称"
                  @keyup.enter.native.prevent="
                    page = 1;
                    searchData();
                  "
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.creator')">
                <el-autocomplete
                  class="inline-input"
                  v-model="formInline.bossManagerName"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="bossManagerName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                style="margin-right: 5px"
                :loading="loading"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.button.add || $route.meta.authority.button.batchDelete"
          ></div>
          <div
            class="col_box h44"
            v-if="$route.meta.authority.button.add || $route.meta.authority.button.batchDelete"
          >
            <div class="col_left">
              <el-button
                type="primary"
                @click="handleAdd({})"
                v-if="$route.meta.authority.button.add"
                >制作节目</el-button
              >
              <el-button
                type="danger"
                @click="moreDelete"
                v-if="$route.meta.authority.button.batchDelete"
                >删除节目</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <el-table :data="tableData" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
          v-for="item in tableCols"
          :key="item.prop"
          :sortable="item.sortable"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <AuthorityComponent
              ComponentName="el-dropdown"
              align="center"
              @command="handleCommand($event, scope.row)"
              width="80"
            >
              <el-button type="text" size="small" style="padding: 0"
                >操作<i class="el-icon-arrow-down"
              /></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a" v-if="$route.meta.authority.button.edit"
                  >修改</el-dropdown-item
                >
                <el-dropdown-item command="b" v-if="$route.meta.authority.button.view"
                  >查看</el-dropdown-item
                >
                <el-dropdown-item command="c" v-if="$route.meta.authority.button.delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </AuthorityComponent>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exportExcelNew,
  dateFormat,
  //   setIndex
} from "@/common/js/public.js";

export default {
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      formInline: {
        programName: "",
        creatorId: "",
      },
      tableCols: [
        {
          prop: "programName",
          label: "节目名称",
        },
        {
          prop: "materialNum",
          label: "素材数量",
        },
        {
          prop: "creatorName",
          label: "创建者",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
        },
      ],
      tableData: [],
      multipleSelection: [],
      dataSelect: [],
    };
  },
  watch: {},
  methods: {
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.handleAdd(data);
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/addProgram",
          query: {
            isDetail: true,
            ...data,
          },
        });
      }
      if (cmd == "c") {
        this.getDelete(data.programId);
      }
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/bossManager/list", {
          data: {
            page: 0,
            size: 20,
            bossManagerName: queryString,
          },
        })
        .then((res) => {
          if (res.value.list.length > 0) {
            this.formInline.creatorId = res.value.list[0].bossManagerId;
          } else {
            this.formInline.creatorId = "";
          }
          cb(res.value.list);
        });
    },
    handleSelect(item) {
      this.formInline.creatorId = item.bossManagerId;
    },
    getDelete(programId) {
      this.$confirm("此操作将删除该条数据, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/ydpProgram/delete", {
              data: {
                programIds: programId || this.dataSelect.join(","),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "删除成功！",
                });
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    moreDelete() {
      if (this.multipleSelection == "") {
        this.$message({
          type: "error",
          message: "请选择需要删除的节目！",
        });
      } else {
        this.getDelete();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val, "kkkk");
      this.dataSelect = [];
      for (var i = 0; i < val.length; i++) {
        this.dataSelect.push(val[i].programId);
      }
      console.log(this.dataSelect, "dataSelect");
    },
    handleAdd(data) {
      this.$router.push({
        path: "/addProgram",
        query: data,
      });
    },
    searchData() {
      if (this.formInline.bossManagerName == "") {
        this.formInline.creatorId = "";
      }
      this.$axios
        .get("/acb/2.0/ydpProgram/list", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            creatorId: this.formInline.creatorId,
            programName: this.formInline.programName,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = Number(res.value.total);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
    .searchWrapper
        padding 22px 22px 0
        background #ffffff
    .pagerWrapper
        text-align right
        font-size 12px
        background #ffffff
        margin-top 0
        .export
            font-size 12px
            .iconfont
                margin-right 0
</style>
